<template>
  <div class="flex flex-col items-center">
    <div class="md:w-1/2 bg-white dark:text-white dark:bg-gray-900 my-5 rounded-md shadow px-5 py-3 mx-8 flex flex-col">
      <div v-html="$t('privacy_text')"></div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer'

export default {
  components: {
    Footer
  }
}
</script>
